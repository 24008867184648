import React, { Component } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useFormik, FormikProvider } from 'formik';

const Formik = require('formik').Formik;
const Field = require('formik').Field;

// Dynamic imports
const Icon = dynamic(() => import('../../../components/Icons/Icon'));
const InputField = dynamic(() => import('../../../components/InputField'));

const Search = ({ isOpen, term, handleSubmit, setOpened }) => {
  const router = useRouter();
  const s = require('./search.module.scss');

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: values => {
      router.push(`/search/${values.search}`)
    },
  });

  return (
    <div className={isOpen || term ? s.SearchOpen : s.Search}>
      <FormikProvider value={formik}>
        <form className={s.SearchForm} onSubmit={formik.handleSubmit}>
          <Field
            id="search"
            name="search"
            className={s.Search_input}
            placeholder="I'm looking for..."
            type="text"
            component="input"
          />
          <button className={s.Search_submit} type="submit">
            <Icon icon="ACTION_SEARCH" theme="dark" size={25} />
          </button>

          <button className={s.Search_close} type="button" onClick={() => setOpened(false)}>
            <Icon icon="ACTION_CLOSE" theme="dark_rounded" size={30} />
          </button>
        </form>
      </FormikProvider>
    </div>
  );
}

Search.propTypes = {
  pushState: PropTypes.func,
  reset: PropTypes.func,
  toggleSearch: PropTypes.func,
  handleSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  term: PropTypes.string
};

export default Search